import * as React from "react"
import Styled from 'styled-components/macro';
import Media from '@uizard-io/styled-media-query';

import Meta from '../../components/Meta';
import Layout from '../../components/Layout';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Content = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  align-items: left;
  margin: 2rem 2rem 6rem 2rem;

  ${Media.greaterThan('large')`
    margin: 9rem auto;
    max-width: 100rem;
  `}
`;

const Title = Styled.h1`
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1;

  ${Media.greaterThan('large')`
    font-size: 5.2rem;
  `}
`;

const Text = Styled.p`
  font-size: 2rem;
  line-height: 1.2;
  text-align: justify;

  ${Media.greaterThan('large')`
    font-size: 2.2rem;
  `}
`;

const Link = Styled.a`
  color: #1e90ff !important;
`;


const TermsOfServicePrivacyPolicy = ({ data }) => (
    <Layout>
      <Meta
        title="Theme Generator"
        description="Theme Generator terms of service"
        url="/terms-of-service"
      />
      <Navbar />
      <Content>
        <Title>Terms Of Service</Title>
        <Text>
          The Theme Generator Services are powered by some of the technologies offered by Uizard (uizard.io).
          <br/>
          WHEN USING THE THEME GENERATOR SERVICES, YOU ACCEPT AND AGREE TO THE TERMS OF SERVICE LOCATED AT <Link href="https://uizard.io/terms-of-service" target="_blank">uizard.io/terms-of-service</Link>.
        </Text>
      </Content>
      <Footer />
    </Layout>
  )

  export default TermsOfServicePrivacyPolicy;

